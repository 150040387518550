import React, { useEffect, useState } from "react";
import "../../Styles/T3/T3PlayerInfo.css";
import t3_player_default_logo from "../../Assets/t3_player_default_logo.png";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import qs from "qs";

function T3PlayerInfo(props) {
  let [searchParams] = useSearchParams();
  let player_id = searchParams.get("player_id");

  const decodeNames = (x) => {
    // var x = "http\\u00253A\\u00252F\\u00252Fexample.com";
    if (x) {
      var r = /\\u([\d\w]{4})/gi;
      x = x.replace(r, function (match, grp) {
        return String.fromCharCode(parseInt(grp, 16));
      });
    }

    return x;
  };

  const [summary, setSummary] = useState({
    player_name: "",
    player_type: "",
    matches: "",
    innings: "",
    runs: "",
    highest_runs: "",
    no_of_fifties: "",
    no_of_hundreds: "",
    player_img: "",
    non_striker_id: "",
  });
  // console.log(props.strikerData.PLAYER_ID,"k")
  let striker_id = props.strikerData;
  // console.log(striker_id,"jk")

  const reqData = {
    mod: "PlayerCareer",
    actionType: "player-career",
    subAction: JSON.stringify({ PLAYER_ID: striker_id.PLAYER_ID }),
  };
  useEffect(() => {
    axios({
      method: "POST",
      url: process.env.REACT_APP_API_URL,
      data: qs.stringify(reqData),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
      },
    }).then((res) => {
      // console.log(res, "res");
      let responseData = res.data.XSCData.CAREER_BATTING;
      setSummary({
        player_name: responseData.FULL_NAME,
        player_type: responseData.STYLE_SHORT_NAME,
        player_img: responseData.IMAGE_URL,
        runs: responseData.RUNS,
        highest_runs: responseData.HIGH_SCORE,
        no_of_hundreds: responseData,
        matches: responseData.MATCHES,
        innings: responseData.INNINGS,
      });
    });
  }, [0]);

  const [showTicker, setShowTicker] = useState(true);

  useEffect(() => {
    setTimeout(function () {
      setShowTicker(!showTicker);
      // setShow("none");
    }, 4000);
  }, []);
  const maxLetterCount = 8;
  const partner1 = document.getElementById("t3_player");

  if (partner1) {
    if (partner1.textContent.length > maxLetterCount) {
      const truncatedText = partner1.textContent.substring(0, maxLetterCount);
      partner1.textContent = truncatedText + "...";
    }
  }

  return (
    <>
      {showTicker ? (
        <div id="t3_player_info" data-aos="zoom-in" data-aos-delay="500">
          <div id="t3_player_title_bg">
            {" "}
            <div id="t3_player_name">
              <img
                src={process.env.REACT_APP_API_URL + summary.player_img}
                alt="player"
                onError={(e) => (e.target.src = t3_player_default_logo)}
              />{" "}
              <span id="t3_player"> {decodeNames(summary.player_name)}</span>
            </div>
            <div id="t3_next_bat_bg">
              <div id="t3_next_bat_text">
                NEXT TO <span>BAT</span>
              </div>
            </div>
          </div>
          <div id="t3_player_info_bg">
            <div className="row" id="t3_player_titles">
              <div className="col-4" id="t3_player_matches">
                <div>MATCHES</div>
                <div id="t3_player_data">{summary.matches}</div>
              </div>
              <div className="col-4" id="t3_player_innings">
                INNS
                <div id="t3_player_data">{summary.innings}</div>
              </div>
              <div className="col-4" id="t3_player_runs">
                RUNS
                <div id="t3_player_data">{summary.runs}</div>
              </div>
            </div>
            <div className="row" id="t3_player_titles">
              <div className="col-4" id="t3_player_matches">
                h/r
                <div id="t3_player_data">{summary.highest_runs}</div>
              </div>
              <div className="col-4" id="t3_player_innings">
                50's
                <div id="t3_player_data">{summary.no_of_hundreds["50S"]}</div>
              </div>
              <div className="col-4" id="t3_player_runs">
                100's
                <div id="t3_player_data">{summary.no_of_hundreds["100S"]}</div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default T3PlayerInfo;
